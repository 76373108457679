'use client';
import { useThemeBreakpointResolve } from '@/components/useThemeBreakpointResolve';

export default function useContainerMinHeight(): number {
  const tbp = useThemeBreakpointResolve();
  return (
    tbp({
      xs: 200,
      sm: 200,
      md: 250,
      lg: 300,
      xl: 400,
    }) ?? 200
  );
}
