'use client';
import { EkkoLogo } from '@/components/common/EkkoLogo';
import { ProGloveDisplayTemplateId, useProGlove } from '@/components/proglove/ProgloveProvider';
import { useThemeBreakpointResolve } from '@/components/useThemeBreakpointResolve';
import { delay } from '@/helper/delay';
import { transformErrorMessage } from '@/helper/errors';
import useContainerMinHeight from '@/helper/useContainerMinHeight';
import useDelayedRouting from '@/helper/useDelayedRouting';
import { ErrorOutline, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonGroup,
  Chip,
  Grid,
  Grow,
  LinearProgress,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import StackTracey from '@sc3d/stacktracey';
import { useParams, usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export enum ErrorType {
  GLOBAL = 0,
  NOT_FOUND = 1,
  COMMON = 2,
}

interface ErrorLayoutProps {
  type: ErrorType;
  countdown: number;
  error?: Error & { digest?: string };
  reset?: () => void;
}

export default function ErrorLayout({ type, countdown, error, reset }: ErrorLayoutProps): React.JSX.Element {
  const themeBreakpointResolve = useThemeBreakpointResolve();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const minHeight = useContainerMinHeight();

  const { countdownTime } = useDelayedRouting('/', countdown * 1000);

  const [stackTracey, setStackTracey] = useState<StackTracey | null>(null);
  const [clicked, setClicked] = useState<boolean>(false);

  const proGloveDisplayUpdate = useRef<boolean>(false);
  const { updateDisplay } = useProGlove();

  const errorTitle = useMemo(() => {
    switch (type) {
      case ErrorType.GLOBAL:
        return 'CRITICAL ERROR';
      case ErrorType.NOT_FOUND:
        return 'Not Found';
      case ErrorType.COMMON:
      default:
        return 'Something went wrong!';
    }
  }, [type]);

  const errorText = useMemo(() => {
    switch (type) {
      case ErrorType.GLOBAL:
        return <Typography variant={'caption'}>An unexpected error occurred</Typography>;
      case ErrorType.NOT_FOUND:
        return <Typography variant={'caption'}>Could not find requested resource</Typography>;
      case ErrorType.COMMON:
      default:
        return <Typography variant={'caption'}>Please try again or return to home screen</Typography>;
    }
  }, [type]);

  useEffect(() => {
    if (!proGloveDisplayUpdate.current) {
      updateDisplay({
        display_template_id: ProGloveDisplayTemplateId.PG1,
        display_fields: [
          {
            display_field_id: 1,
            display_field_header: errorTitle,
            display_field_text: `returning to home-screen in ${countdown} seconds...`,
          },
        ],
      });
      proGloveDisplayUpdate.current = true;
    }

    return () => {
      proGloveDisplayUpdate.current = false;
    };
  }, [countdown, errorTitle, updateDisplay]);

  const prettyPathname = useMemo(() => {
    const chunks = pathname
      .split('/')
      .filter(Boolean)
      .map((chunk) => {
        const paramKey = Object.keys(params).find((key) => params[key] === chunk);

        return { chunk, type: paramKey ?? null };
      });

    return (
      <Typography component={'div'} sx={{ fontSize: 18, fontWeight: 700, pb: 1 }}>
        <Stack
          direction={'row'}
          spacing={2}
          divider={<Box sx={(theme) => ({ color: theme.palette.grey[500] })}>/</Box>}
        >
          {chunks.map((chunk, i) => (
            <Stack key={i} direction={'column'} textAlign={'center'}>
              <Box>{chunk.chunk}</Box>
              <Box>{chunk.type !== null && <Chip label={chunk.type} size={'small'} sx={{ fontWeight: 400 }} />}</Box>
            </Stack>
          ))}
        </Stack>
      </Typography>
    );
  }, [pathname, params]);

  const stackTraceTable = useMemo(() => {
    if (stackTracey === null) {
      return null;
    }

    return stackTracey.items.map((entry, i) => {
      return (
        <TableRow key={i}>
          <TableCell>{entry.callee}</TableCell>
          <TableCell>{entry.fileRelative + ':' + entry.line + ':' + entry.column}</TableCell>
        </TableRow>
      );
    });
  }, [stackTracey]);

  const errorElement = useMemo(() => {
    if (error === undefined) {
      return null;
    }

    return (
      <Accordion sx={(theme) => ({ borderTop: theme.palette.error.main + ' 2px solid' })}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 1.5 }} aria-controls="error-content" id="error-header">
          <Stack direction={'row'} spacing={2}>
            <ErrorOutline color={'error'} />
            <Typography sx={{ fontWeight: 700 }}>Error:</Typography>
            <Typography>{transformErrorMessage(error)}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table size={'small'}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ verticalAlign: 'top', pl: 4 }}>
                    <Typography sx={{ fontWeight: 700, wordBreak: 'normal' }}>Path:</Typography>
                  </TableCell>
                  <TableCell>{prettyPathname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ verticalAlign: 'top', pl: 4 }}>
                    <Typography sx={{ fontWeight: 700, wordBreak: 'normal' }}>Stack:</Typography>
                  </TableCell>
                  <TableCell>
                    <TableContainer
                      sx={{
                        zoom: themeBreakpointResolve({
                          md: 1,
                          sm: 0.6,
                        }),
                      }}
                    >
                      <Table size={'small'}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography sx={{ fontWeight: 600, wordBreak: 'normal' }}>callee</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontWeight: 600, wordBreak: 'normal' }}>file</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{stackTraceTable}</TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }, [error, prettyPathname, stackTraceTable, themeBreakpointResolve]);

  useEffect(() => {
    if (error !== undefined) {
      void (async () => {
        setStackTracey(await (await new StackTracey(error.stack).withSourcesAsync()).cleanAsync());
      })();
    }
  }, [countdown, error]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SvgIcon
            component={EkkoLogo}
            color={'secondary'}
            fontSize={themeBreakpointResolve({
              xl: 'ekkoLogoXL',
              sm: 'ekkoLogoXS',
            })}
          />
          <Box>
            <Grow in={true}>
              <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ minHeight }}>
                <Grid item>
                  <Typography
                    variant={'h4'}
                    sx={{
                      zoom: themeBreakpointResolve({
                        sm: 1,
                        xs: 0.8,
                      }),
                    }}
                  >
                    {errorTitle}
                  </Typography>
                </Grid>
                <Grid item>{errorText}</Grid>
                <Grid item sx={{ width: '85%' }}>
                  <Paper square elevation={0} sx={{ px: 0, py: 2, bgcolor: 'background.default' }}>
                    <ButtonGroup variant="contained" disableElevation fullWidth>
                      {reset !== undefined && (
                        <Button
                          sx={{ mt: 3, mb: 2, overflow: 'hidden', textWrap: 'nowrap' }}
                          disabled={clicked}
                          onClick={() => {
                            void (async () => {
                              setClicked(true);
                              await delay(1500);
                              reset();
                            })();
                          }}
                        >
                          Try again
                        </Button>
                      )}
                      <Button
                        sx={{ mt: 3, mb: 2, overflow: 'hidden', textWrap: 'nowrap' }}
                        disabled={clicked}
                        onClick={() => {
                          setClicked(true);
                          router.push('/');
                        }}
                      >
                        <LinearProgress
                          sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
                          variant="determinate"
                          color={'secondary'}
                          value={100 / (countdown / countdownTime)}
                        />
                        Return Home
                      </Button>
                    </ButtonGroup>
                  </Paper>
                </Grid>
              </Grid>
            </Grow>
          </Box>
        </Box>
      </Container>
      <Container
        maxWidth={'lg'}
        sx={{
          zoom: themeBreakpointResolve({
            sm: 1,
            xs: 0.7,
          }),
        }}
      >
        <Box>{errorElement}</Box>
      </Container>
    </>
  );
}
